/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:5d9cf5da-7ed9-4ab3-ae84-d72ed84c32bb",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Ssp3SnDuw",
    "aws_user_pools_web_client_id": "229s3427qasaai71ttbfrvjce7",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "jennyportfoliodd6927fc1bca4ef3851e9711cebe8ed3a029e-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
